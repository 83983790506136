import clsx from "clsx";
import { useDispatch, useSelector } from "react-redux";

import {
  selectDrawerModalIsOpen,
  toggleDrawerModal,
} from "@chef/state-management";
import { BurgerButton } from "@chef/components";
import { language } from "@chef/constants";

import { LoyaltyBadge } from "./LoyaltyBadge";

export const DrawerButton = () => {
  const dispatch = useDispatch();
  const drawerOpen = useSelector(selectDrawerModalIsOpen);

  const handleToggleDrawer = () => {
    dispatch(toggleDrawerModal());
  };

  return (
    <BurgerButton
      id="open-menu-burger-button"
      title={intl.OPEN_MENU_LABEL}
      onClick={handleToggleDrawer}
      className={clsx(
        "rounded stroke-black px-1",
        !drawerOpen && "hover:bg-grey-2",
      )}
    >
      <LoyaltyBadge className="stroke-none" />
    </BurgerButton>
  );
};

const intl = (
  {
    no: {
      OPEN_MENU_LABEL: "Åpne meny",
    },
    se: {
      OPEN_MENU_LABEL: "Öppna meny",
    },
    dk: {
      OPEN_MENU_LABEL: "Åbn menu",
    },
  } as const
)[language];
